import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import { Routes, Route } from "react-router-dom";
import Login from './pages/login/login';
import InwardDashboard from './pages/dashboard/inward/inwarddashboard';

import Inwardloglist from './pages/inward/inwardloglist';
import InwardLog from './pages/inward/inwardlog';
import InwordDetailsList from './pages/inward/inwarddetailslist';
import InwordDetails from './pages/inward/inwarddetail';
import Helpdesktracking from './pages/inward/helpdesktracking';


import Outwordlog from './pages/outward/outwordlog';
import Outwarddetail from './pages/outward/Outwarddetailentry';
import Primarysettings from './pages/Settings/primarysettings';

import InwardDelivarySheet from './pages/inward/InwardDelivarySheet';

import Master1 from './pages/Masters/master1';
import Delivery from './pages/Masters/delivery';
import Shipmentmode from './pages/Masters/shipmentmode';
import Inwarddbstatus from './pages/inward/inwarddbstatus';
import InwardMail from './pages/inward/InwardMail';
import Shipmentmodelist from './pages/Masters/shipmentmodelist';
import Deliverylist from './pages/Masters/deliverylist';
import Serviceprovider from './pages/Masters/serviceprovider';
import Serviceproviderlist from './pages/Masters/serviceproviderlist';
import Employee from './pages/Masters/Employee';
import EmployeeList from './pages/Masters/EmployeeList';
import Header from './pages/headerfooter/Header';
import Footer from './pages/headerfooter/Footer';
import Regulatorslist from './pages/Masters/regulatorslist';
import Regulators from './pages/Masters/regulators';
import MasterHome from './pages/Masters/masterhome';
import BranchList from './pages/Settings/branchlist';
import Branch from './pages/Settings/branch';
import CostCenter from './pages/Masters/costcenter';
import CostCenterList from './pages/Masters/costCenterlist';
import Department from './pages/Masters/department';
import DepartmentList from './pages/Masters/departmentlist';
import RouteDetail from './pages/Masters/routeDetail';
import RouteList from './pages/Masters/routelist';
import CountryList from './pages/Masters/location/countrylist';
import Country from './pages/Masters/location/country';
import StateList from './pages/Masters/location/statelist';
import StateMaster from './pages/Masters/location/statemaster';
import CityList from './pages/Masters/location/citylist';
import CityMaster from './pages/Masters/location/citymaster';
import PincodeList from './pages/Masters/location/pincodelist';
import PincodeMaster from './pages/Masters/location/pincodemaster';
import TransactionHeader from './pages/transaction/transactionHeader';
import OutwardDashboard from './pages/dashboard/outward/outwarddashboard';
import InwardDrslist from './pages/inward/inwarddrslist';
import DrsPrint from './pages/inward/drsprint';
import InwardEmailIntimation from './pages/inward/inwardemailintimation';
import ZoneList from './pages/Masters/location/zonelist';
import ZoneMaster from './pages/Masters/location/zonemaster';
import Outwardloglist from './pages/outward/OutwardLogList';
import OutwardDetailsList from './pages/outward/OutwardDetailsList';
import AccountHome from './pages/Account/accountHome';
import Product from './pages/Account/vendorContract/product';
import ProductList from './pages/Account/vendorContract/productList';
import VendorZone from './pages/Account/vendorContract/zone';
import RateContract from './pages/Account/vendorContract/rateContract';
import VendorZoneList from './pages/Account/vendorContract/zoneList';

import ProtectedRoute from './Auth/ProtectedRoute';
import AdminRoute from './Auth/AdminRoute';
import Logout from './pages/login/logout';
import Mailroom from './pages/Settings/mailroom';
import Settings from './pages/Settings/settings';
import SettingHome from './pages/Settings/settingHome';
import Users from './pages/Settings/users';
import UserList from './pages/Settings/userlist';
import RateContractList from './pages/Account/vendorContract/ratecontractlist';
import SettingUpdates from './pages/login/settingupdates';
import UpdateStatus from './pages/userside/updatestatus';
//for Report
import InwardReport from './pages/reports/inwardreport';
import OutwardReport from './pages/reports/outwardreport';
import MISReport from './pages/reports/misreport';
import InwardTracking from './pages/inward/inwardtracking';

import OutwardMenifestList from './pages/outward/outwardmanifestlist';
import OutEmailIntimation from './pages/outward/outemailintimation';
import Outwarddbstatus from './pages/outward/Outwarddbstatus';
import AdminMaterialList from './pages/outward/adminmateriallist';
import AdminMaterial from './pages/outward/adminmaterial';
import OutwardManifest from './pages/outward/Outwardmanifest';
import OutwardTracking from './pages/outward/outwardtracking';
import HelpDesk from './pages/Masters/helpdesk';
import HelpDeskList from './pages/Masters/helpdesklist';
import AdminMaterialReport from './pages/reports/adminmaterialoutreport';
import UploadStatus from './pages/outward/uploadstatus';
import UploadInwardStatus from './pages/inward/uploadstatus';
import EmployeeImport from './pages/Masters/employeeImport';
import InwardDetailImport from './pages/inward/inwarddetailsimport';
import PincodeImport from './pages/Masters/location/pincodeimport';
import RegulatorsImport from './pages/Masters/regulatorsimport';
import CostCenterImport from './pages/Masters/costCenterImport';
import OutwardReconciliation from './pages/Account/outwardcheck/outwardreconciliation';
import Mailfeedback from './pages/inward/mailfeedback';
import Inwardbulkupdate from './pages/inward/inwardbulkupdate';
import OutwardDetailImport from './pages/outward/outwarddetailsimport';
import Outwardbulkupdate from './pages/outward/outwardbulkupdate';
// import AdminRoute from './Auth/AdminRoute';s

function App() {
  return (
    <div id='my-component h-100' >
      {/* <button onClick={() => navigate(+1)}>go forword</button>
        <button onClick={() => navigate(-1)}>go Back</button> */}
      {/* <Nav/> */}
      {localStorage.getItem('userDetails') ?  <Header />  : ''}   
      {/* <BrowserRouter basename="/mailroom"> */}
      <div style={{ paddingBottom: "20px" }}>
       <Routes>
      <Route path="/dashboard" element={<ProtectedRoute element={<InwardDashboard />} />} />
        <Route path="/outwarddashboard" element={<ProtectedRoute element={<OutwardDashboard />} /> } /> 
        <Route path="/inwardloglist" element={<ProtectedRoute element={<Inwardloglist />}/> } />
        <Route path="/inwardlog" element={<ProtectedRoute element={<InwardLog />}/> } />
        <Route path="/inworddetailslist" element={<ProtectedRoute element={<InwordDetailsList />}/> } />
        <Route path="/inworddetails" element={<ProtectedRoute element={<InwordDetails />}/> } />
        <Route path="/inwarddelivarysheet" element={<ProtectedRoute element={<InwardDelivarySheet />}/> } />
        <Route path="/inwarddbstatus" element={<ProtectedRoute element={<Inwarddbstatus />}/> } />
        <Route path="/inwardmail" element={<ProtectedRoute element={<InwardMail />}/> } />
        <Route path="/inwarddrslist" element={<ProtectedRoute element={<InwardDrslist />}/> } />
        <Route path="/drsprint" element={<ProtectedRoute element={<DrsPrint />}/> } />
        <Route path="/inwardemail" element={<ProtectedRoute element={<InwardEmailIntimation />}/> } />
        <Route path="/inwardtracking" element={<ProtectedRoute element={<InwardTracking />}/> } />
        <Route path="/inwardimport" element={<ProtectedRoute element={<InwardDetailImport />}/> } />
        <Route path="/uploadinwardstatus" element={<ProtectedRoute element={<UploadInwardStatus/>}/> } />
        <Route path="/helpdesktracking" element={<ProtectedRoute element={<Helpdesktracking />}/> } />
        <Route path="/inwardbulkupdate" element={<ProtectedRoute element={<Inwardbulkupdate />}/> } />

        <Route path="/outwardreconciliation" element={<ProtectedRoute element={<OutwardReconciliation />}/> } />

        <Route path="/outwardlog" element={<ProtectedRoute element={<Outwordlog />}/> } />
        <Route path="/outworddetails" element={<ProtectedRoute element={<Outwarddetail />}/> } />
        <Route path="/outwardloglist" element={<ProtectedRoute element={<Outwardloglist />}/> } />
        <Route path="/outwarddetailslist" element={<ProtectedRoute element={<OutwardDetailsList />}/> } />
        <Route path="/outwardmenifestlist" element={<ProtectedRoute element={<OutwardMenifestList />}/> } />
        <Route path="/outwardmanifest" element={<ProtectedRoute element={<OutwardManifest />}/> } />
        <Route path="/outwardmail" element={<ProtectedRoute element={<OutEmailIntimation />}/> } />
        <Route path="/outwardstatus" element={<ProtectedRoute element={<Outwarddbstatus />}/> } />     
        <Route path="/uploadstatus" element={<ProtectedRoute element={<UploadStatus />}/> } />        
        <Route path="/outwardtracking" element={<ProtectedRoute element={<OutwardTracking />}/> } />
        <Route path="/outwardimport" element={<ProtectedRoute element={<OutwardDetailImport />}/> } />
        <Route path="/outwardbulkupdate" element={<ProtectedRoute element={<Outwardbulkupdate />}/> } />
        <Route path="/adminmateriallist" element={<ProtectedRoute element={<AdminMaterialList />}/> } />
        <Route path="/adminmaterial" element={<ProtectedRoute element={<AdminMaterial />}/> } />


        <Route path="/master" element={<ProtectedRoute element={<Master1 />}/> } />
        <Route path="/masterhome" element={<ProtectedRoute element={<MasterHome />}/> } />
        <Route path="/delivery" element={<ProtectedRoute element={<Delivery />}/> } />
        <Route path="/employee" element={<ProtectedRoute element={<Employee/>}/> } />
        <Route path="/shipmentmode" element={<ProtectedRoute element={<Shipmentmode />}/> } />
        <Route path="/serviceprovider" element={<ProtectedRoute element={<Serviceprovider id={undefined}/> } />}/> 
        <Route path="/shipmentmodelist" element={<ProtectedRoute element={<Shipmentmodelist />}/> } />
        <Route path="/deliverylist" element={<ProtectedRoute element={<Deliverylist />}/> } />
        <Route path="/employeelist" element={<ProtectedRoute element={<EmployeeList />}/> } />
        <Route path="/importemployee" element={<ProtectedRoute element={<EmployeeImport />}/> } />
        <Route path="/serviceproviderlist" element={<ProtectedRoute element={<Serviceproviderlist />}/> } />
        <Route path="/regulatorslist" element={<ProtectedRoute element={<Regulatorslist/>}/> } />
        <Route path="/regulators" element={<ProtectedRoute element={<Regulators/>}/> } />
        <Route path="/regulatorsimport" element={<ProtectedRoute element={<RegulatorsImport/>}/> } />
        <Route path="/costcenter" element={<ProtectedRoute element={<CostCenter id={undefined}/>}/> } />
        <Route path="/costcenterlist" element={<ProtectedRoute element={<CostCenterList/>}/> } />
        <Route path="/costcenterimport" element={<ProtectedRoute element={<CostCenterImport/>}/> } />
        <Route path="/department" element={<ProtectedRoute element={<Department id={undefined}/>}/> } />
        <Route path="/departmentlist" element={<ProtectedRoute element={<DepartmentList/>}/> } />
        <Route path="/route" element={<ProtectedRoute element={<RouteDetail id={undefined}/>}/> } />
        <Route path="/routelist" element={<ProtectedRoute element={<RouteList/>}/> } />
        
        <Route path="/countrylist" element={<ProtectedRoute element={<CountryList/>}/> } />
        <Route path="/country" element={<ProtectedRoute element={<Country/>}/> } />
        <Route path="/statelist" element={<ProtectedRoute element={<StateList/>}/> } />
        <Route path="/statemaster" element={<ProtectedRoute element={<StateMaster/>}/> } />
        <Route path="/citylist" element={<ProtectedRoute element={<CityList/>}/> } />
        <Route path="/citymaster" element={<ProtectedRoute element={<CityMaster/>}/> } />
        <Route path="/pincodelist" element={<ProtectedRoute element={<PincodeList/>}/> } />
        <Route path="/pincodemaster" element={<ProtectedRoute element={<PincodeMaster/>}/> } />
        <Route path="/pincodeimport" element={<ProtectedRoute element={<PincodeImport/>}/> } />
        <Route path="/zonelist" element={<ProtectedRoute element={<ZoneList />}/> } />
        <Route path="/zone" element={<ProtectedRoute element={<ZoneMaster  />}/> } />
        <Route path="/helpdesk" element={<ProtectedRoute element={<HelpDesk id={undefined}/>}/> } />
        <Route path="/helpdesklist" element={<ProtectedRoute element={<HelpDeskList/>}/> } />
        <Route path="/transaction" element={<ProtectedRoute element={<TransactionHeader/>}/> } />

        <Route path="/accounthome" element={<ProtectedRoute element={<AccountHome/>}/> } />
        <Route path="/product" element={<ProtectedRoute element={<Product/>}/> } />
        <Route path="/productlist" element={<ProtectedRoute element={<ProductList/>}/> } />
        <Route path="/vendorzone" element={<ProtectedRoute element={<VendorZone/>}/> } />
        <Route path="/vendorzonelist" element={<ProtectedRoute element={<VendorZoneList/>}/> } />
        <Route path="/ratecontract" element={<ProtectedRoute element={<RateContract/>}/> } />
        <Route path="/ratecontractlist" element={<ProtectedRoute element={<RateContractList/>}/> } />

        <Route path="/mailroom" element={<AdminRoute element={<Mailroom/>} />} />
        <Route path="/settings" element={<AdminRoute element={<Settings/>}/> } />
        <Route path="/basicsettings" element={<AdminRoute element={<Primarysettings/>}/> } />

        
        <Route path="/settinghome" element={<AdminRoute element={<SettingHome/>} />} />
        <Route path="/branchlist" element={<AdminRoute element={<BranchList/>}/> } />
        <Route path="/branch" element={<AdminRoute element={<Branch id={undefined}/>}/> } />
        <Route path="/userlist" element={<AdminRoute element={<UserList/>}/> } />
        <Route path="/users" element={<AdminRoute element={<Users id={undefined}/>}/> } />
        <Route path="/settingupdates" element={<ProtectedRoute element={<SettingUpdates />}/> } />
        

        <Route path="/logout" element={<Logout/>} /> 
        <Route path="/updatestatus/:inwardId/:statusId" element={<UpdateStatus/>} />
        <Route path="/" element={<Login/>}/>
        <Route path="/mailfeedbackonstatus/:param1/:param2" element={<Mailfeedback/>}/>
 
        {/* Reports */}

        <Route path="/inwardreport" element={<ProtectedRoute element={<InwardReport/>}/> } />
        <Route path="/outwardreport" element={<ProtectedRoute element={<OutwardReport/>}/> } />
        <Route path="/misreport" element={<ProtectedRoute element={<MISReport/>}/> } />
        <Route path="/adminmaterialoutreport" element={<ProtectedRoute element={<AdminMaterialReport/>}/> } />
       </Routes>
       </div>
       {/* </BrowserRouter>   */}
      {localStorage.getItem('userDetails') ?  <Footer />  : ''}
    </div>
  );
}

export default App;
