import React, { useState, useEffect, useRef } from 'react'
// import '../inward/inwarddbstatus.css';
import Outcontainerheader from './Outcontainerheader';
import OutwardLogService from '../../services/outwardlog.service';
import OutwardDetailService from '../../services/outwarddetail.sercice';
import { Link, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import OutwardManifest from '../../services/manifest.service';
import moment from 'moment';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import Select from 'react-select';
import MasterDataService from "../../services/masterData.service";
import AsyncSelect from 'react-select/async'; 




const Outwardbulkupdate = () => { 
    
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [meniDetails, setmeniDetails] = useState([]);
    const [manifestId, setmanifestId] = useState(0);

    const [fromAccount, setFromAccount] = useState([])
    const [accountNo, setAccountNO] = useState(0);
    const [accountNoLable, setAccountNoLable] = useState('Select Account No');
    const [hideshowtable, sethideshowtable] = useState(false);

 
//for filters new
const [allInwardDetails, setAllInwardDetails] = useState([]);
const [EmployeeList, setEmployeeList] = useState([]);
const [Employee, setEmployee] = useState('Employee Name');
const [EmployeeId, setEmployeeId] = useState(0);
const [AWBNo, setAWBNo] = useState('AWB No'); 
const [StatusList, setStatusList] = useState<any | []>([]);
const [StatusId, setStatusId] = useState(0);
const [Status, setStatus] = useState('Pending');
const [Fromdate, setFromdate] = useState((moment(new Date())).format('YYYY-MM-DD'));
const [Todate, setTodate] = useState((moment(new Date())).format('YYYY-MM-DD'));
const [ModeList, setModeList] = useState([]);
const [Mode, setMode] = useState('Select Mode');
const [ModeId, setModeId] = useState(0);
const [RouteList, setRouteList] = useState([]);
const [Route, setRoute] = useState('Select Route');
const [RouteId, setRouteId] = useState(0);
const [DepartmentList, setDepartmentList] = useState([]);
const [Department, setDepartment] = useState('Select Department');
const [DepartmentId, setDepartmentId] = useState(0);
const [serviceProvider, setserviceProvider] = useState('Select Service Provider');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [product, setproduct] = useState(0);
  const [productName, setproductName] = useState('Select Product'); 
  const [isChecked, setIsChecked] = useState(false);


  useEffect(()=>{
    getEmployee();
    getMode();
    getDepartment();
    getRoute();
    getReportType();
    getServiceProvider();
    setFromdate((moment(new Date())).format('YYYY-MM-DD'))
    setTodate((moment(new Date())).format('YYYY-MM-DD'))
  },[]);

  useEffect(() => {
    if (isChecked === true) {
      sethideshowtable(false)
    } else {
      sethideshowtable(true)
    }
  }, [isChecked])

  const checkEntryType = () => {
    setIsChecked(!isChecked);
  }

  useEffect(()=>{  
    setserviceProviderList([])  
    getServiceProvider();
  },[ModeId])

  useEffect(() => {
    OutwardDetailService.vendoraccount(serviceProviderId).then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.accountNo,
          }))
          options.unshift({ value: 0, label: "Select Account No" });
          await setFromAccount(options)
        }
      }
    )
  }, [serviceProviderId])

 
  const handleServiceProvider = (selectedOption:any) => {
    setserviceProviderId(selectedOption.value);
    setserviceProvider(selectedOption.label)
  }
  const handleproduct = (selectedOption:any) => {
    setproduct(selectedOption.value);
    setproductName(selectedOption.label)
  }
  
  const getServiceProvider = async () => {
    MasterDataService.GetserviceProvider().then(
    async (data) => {
     if(data) {        
     const options = data
     .filter((d: any) => d.shipmentModeId === ModeId)
     .map((d: any) => (
       {
       "value": d.id,
       "label": d.serviceProviderName
     }))
     options.unshift({ value: 0, label: "All service provider" });
      setserviceProviderList(options)
   }
   }
 )
 
}

  const handleInputChangeAwb = async (input: any) => {
    if (input.length > 3) {
      return await OutwardDetailService.getAwbNumberbyAutoComplte(input).then(
        (data) => {
          const awbNumberList = data.map((d: any) => ({
            "value": d.AWB,
            "label": d.AWB
          }))
          return awbNumberList;
        }
      )
    }
  };
const handleEmployee = (e:any) => {
  console.log(JSON.stringify(e));
  setEmployeeId(e.value);
  setEmployee(e.label)
}
const handleStatus = (e:any) => {
  setStatusId(e.value);
  setStatus(e.label)
}

const handleMode = (e:any) => {
  setModeId(e.value);
  setMode(e.label);
}

const handleDepartment = (e:any) => {
  setDepartmentId(e.value);
  setDepartment(e.label)
}

const handleRoute = (e:any) => {
  setRouteId(e.value);
  setRoute(e.label)
}

const handleAccountNo = (e:any) => {
    setAccountNO(e.value);
    setAccountNoLable(e.label)
  }
   
 
  const getRoute = async () => {
   await MasterDataService.getRouteId().then(
     async (data) => {
       const options = data.map((d: any) => ({
         "value": d.id,
         "label": d.routeName
       }))
       options.unshift({ value: 0, label: "Select Route" });
       await setRouteList(options)
    }
   )
 }
 
 const getDepartment = async () => {
   await MasterDataService.getDepartment().then(
     async (data) => {
       if(data){
       const options = data.map((d: any) => ({
         "value": d.id,
         "label": d.name
       }))
       options.unshift({ value: 0, label: "Select Department" });
       await setDepartmentList(options)
      }
    }
   )
 }
 
 
  const getMode = () => {
   MasterDataService.GetshipmentMode().then(
       async (data) => {
         if(data) {            
         const options = data.map((d: any) => ({
           "value": d.id,
           "label": d.modeName
         }))
         options.unshift({ value: 0, label: "Select Mode" });
         await setModeList(options)
       }
       }
   );
 }
 
  const getEmployee = () => {
    MasterDataService.getEmployeeList().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          options.unshift({ value: 0, label: "Select Employee" });
          setEmployeeList(options)
          setEmployeeId(0)
          setEmployee("All Employee")
        }
        }
    );
  }



  const getReportType = () => {
           
    const options =  [
    {
        "value": 0,
        "label": "Pending"
    },
  ]
      setStatusList(options)
      setStatusId(options[0]['value'])
      setStatus(options[0]['label'])
}






    // useEffect(() => {
    //     getMeniList()
    // }, [])
    useEffect(() => {
        getOutwardDetailList();
       }, [Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,RouteId,DepartmentId])
       

       const getOutwardDetailList = async () => {
        await OutwardDetailService.getdataforhdtracking(Fromdate,Todate,EmployeeId,StatusId,AWBNo,ModeId,DepartmentId,RouteId,serviceProviderId,product).then(
          (data) => {
            if (data) {
                setmeniDetails(data);
            }
          }
        );
      }
    const handleRowClick = (meniId: number) => {
        setmanifestId(meniId)
        OutwardManifest.getManifestDetailsbyId(meniId).then(
            (data) => {
                console.log("data", data)
                setmeniDetails(data);
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    };
    const [outwarddetailStatus, setoutwarddetailStatus] = useState(0);

    const [receivedBy, setreceivedBy] = useState('');
    const [remark, setremark] = useState('');
    const [outwardDate, setoutwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
    const [outwardTime, setoutwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
    const [showSign, setShowSign] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusReasons, setstatusReasons] = useState([]);
    const [outwarddetailList, setoutwarddetailList] = useState([]);
    const [ReasonId, setReasonId] = useState(0);
    const [ReasonLabel, setReasonLabel] = useState('');
    const handleOptionChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setoutwarddetailStatus(Number(event.target.value));
        if (event.target.value === '8') {
            setShowSign(true)
        } else {
            setShowSign(false)
        }
        if (event.target.value === '9') { setReasonId(0) }
    };
    const signatureCanvasRef = useRef<any>(null);


    const HandleSubmit = () => {
        if ((outwarddetailStatus !== null) && (outwarddetailList.length !== 0)) {
            let deliverySignbase64 = "";
            if (signatureCanvasRef.current) {
                deliverySignbase64 = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
                // setdeliverySign(signatureImage);
            }
            // console.log("deliverySignbase64", deliverySignbase64);

            const outwarddetailid = outwarddetailList.map((outId: any) => {
                return outId.id
            })
            // inwarddetailid.push(inwardId)
            OutwardLogService.postupdateoutwarddetailstatus(outwarddetailid, outwarddetailStatus, receivedBy, remark, outwardDate, outwardTime, deliverySignbase64, ReasonId, ReasonLabel, manifestId).then(
                (data) => {
                    if (data) {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: "Outward Status Updated Successfully",
                            showConfirmButton: false,
                            timer: 1500,
                            customClass: {
                                title: 'swal-fire-alert', // Add your custom CSS class here
                            },
                        }).then(function () {
                            navigate('../outwarddetailslist');
                        });
                    }
                    // console.log(data.statuscode)
                    // if (data.statuscode === 200) {
                    //     setinwarddetailStatus(data.message[0].inwarddetailStatus);
                    // }
                }
            ).catch((error) => {
                console.log(error);
                console.log("server responding");
            })
        }
    }

    const HandleTracking=()=>{

    }
    function displaySign() {
    }

    const clearSignature = () => {
        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.clear();
        }
    }

    useEffect(() => {
        getRtoReasons()
    }, [outwarddetailStatus])
    const getRtoReasons = () => {
        OutwardManifest.getRtoReasons(outwarddetailStatus, 1).then(
            (data) => {
                if (data) {
                    const options = data.map((d: any) => ({
                        "value": d.id,
                        "label": d.returnReason
                    }))
                    setstatusReasons(options)
                }
            }
        ).catch((error) => {
            console.log(error);
            console.log("server responding");
        })
    }

    const dateformater = (data: any, row: any) => {
        return (moment(data)).format('DD/MM/YYYY')
    }
    interface selectRowType {
        mode: any;
        clickToSelect: boolean;
        style: any;
        onSelect: any;
        onSelectAll: any
    }
 
    const selectRow: selectRowType = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: (row: never, isSelect: any, rowIndex: any, e: any) => {
            if (isSelect) { 
                setoutwarddetailList([...outwarddetailList, row])
            } else {
                const secondIndex = outwarddetailList.findIndex((Selectrow: any) => Selectrow['id'] === row['id']);
                outwarddetailList.splice(secondIndex, 1);
                console.log("outwarddetailList", outwarddetailList)
            }
        },
        onSelectAll: async (isSelect: any, rows: any, e: any) => {
            if (isSelect) {
                var IdArray: [] = [];
                rows.map(async (rowDatas: never) => {
                    IdArray.push(rowDatas);
                })
                await setoutwarddetailList(IdArray)
            } else {
                await setoutwarddetailList([])
            }
        },
        style: (row: any, rowIndex: any) => {
            const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
            return { backgroundColor };
        }
    }
    const columns: ColumnDescription<selectRowType>[] = [
        {
            dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
                return rowIndex + 1;
                // return cell;
            }, text: 'Sr No', align: 'center'
        },
        { dataField: 'AWB', text: 'AWB No' },
        { dataField: 'outwardDate', text: 'Outward Date' },
         { dataField: 'numberOfPieces', text: 'Pcs.',  style: { fontWeight: 'bold', width: "75px" } },
        { dataField: 'consigneeName', text: 'Consignee',  },
        { dataField: 'consigneeCityName', text: 'Destination City',  },
        { dataField: 'consigneeZipCode', text: 'Destination Pincode',  },
        { dataField: 'employeeName', text: 'Employee', },
        { dataField: 'remark', text: 'Remark', },


    ];
   
    const filteredDataManifest =  Array.isArray(rowData)
    ? rowData.filter((row) => {
        const values = Object.values(row);
        return values.some(
            (value: unknown): boolean => {
                if (typeof value === 'string' || typeof value === 'number') {
                    const stringValue = String(value).toLowerCase();
                    return stringValue.includes(searchQuery.toLowerCase());
                }
                return false;
            }
        );
    })
    : [];
    const filteredData = Array.isArray(meniDetails)
        ? meniDetails.filter((selectedRecords) => {
            if (!selectedRecords) {
                return false; // Skip filtering for null or undefined values
              }
            const values = Object.values(selectedRecords);
            return values.some(
                (value: unknown): boolean => {
                    if (typeof value === 'string' || typeof value === 'number') {
                        const stringValue = String(value).toLowerCase();
                        return stringValue.includes(searchQuery.toLowerCase());
                    }
                    return false;
                }
            );
        })
        : [];
    return (
        <>
            <Outcontainerheader />
            <div className='mb-10'>
                <div style={{ margin: "0px" }}>
                    <div className='' style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                        <div className="container col-11" style={{ padding: '0px' }}>
                        <div className="themeColor width" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <p className='col-3 '>Update Status</p>
                                <div className='col-2' style={{textAlign: 'right'}}>
                                <input type='checkbox' checked={isChecked} onChange={checkEntryType} />Online
                                </div>
                                <div className='col-3 insameline' >
                                 <AsyncSelect name="AwbNo"
                                            onChange={(e: any) => {setAWBNo(e.value)}}
                                            loadOptions={handleInputChangeAwb}
                                            value={{ value: AWBNo, label: AWBNo }}
                                             
                                            />
                                            </div>
                                <div className='insameline col-4' style={{ marginRight: '10px' }}>
                                    <input type='date' className='col-6 zeroPadding' value={Fromdate} max={moment().format("YYYY-MM-DD")} name='fromdate' onChange={e => setFromdate(e.target.value)} />
                                    <input type='date' className='col-6 zeroPadding' value={Todate} max={moment().format("YYYY-MM-DD")} name='todate' onChange={e => setTodate(e.target.value)} />
                                </div>
                        </div>
                        <div className='col-12 inline' style={{padding:'0px'}}>
                             <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", paddingTop: '1%' }} >
             
              <div className='col-2' >
              <Select name="ModeId"
                      onChange={handleMode}
                      options={ModeList}
                      value={{ value: ModeId, label: Mode }}
                    />
             
              </div>
              <div className='col-2' >
              <Select name="fromb"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
             
              </div>
              
            
              <div className='col-2' >
              <Select name="accountNo"
                      onChange={handleAccountNo}
                      options={fromAccount}
                      value={{ value: accountNo, label: accountNoLable }}
                    />
             
              </div>
              <div className='col-2' >
             <Select name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      value={{ value: EmployeeId, label: Employee }}
                    />
              </div>
              <div className='col-2' >
              <Select name="departmentId"
                      onChange={handleDepartment}
                      options={DepartmentList}
                      value={{ value: DepartmentId, label: Department }}
                   />
              </div>
              <div className='col-2' >
              <Select name="routeId"
                      onChange={handleRoute}
                      options={RouteList}
                      value={{ value: RouteId, label: Route }}
                   />
              </div>
              
            </div>
            </div> 
                       <div className='col-12 inline' style={{ padding: '2%' }}>
                               <div className='col-12'>
                                    <div className="table-responsive">
                                        <BootstrapTable
                                            keyField='id'
                                            striped
                                            hover
                                            condensed
                                            data={filteredData}
                                            filter={filterFactory()}
                                            filterPosition="top"
                                            columns={columns}
                                            selectRow={selectRow}
                                        />
                                    </div>
                                </div>

                            </div>
                            {!isChecked && (
                            <div>
                            <div className='card container'>
                            <div className='col-12 inline'>
                                        <div className='col-10 inline'>
                                            <label className='Radiobtn col-2 inline' onClick={displaySign}><input type='radio' className='radio-btn'
                                                value="8"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 8}
                                                onChange={handleOptionChange1}

                                            /> Delivered</label>

                                            <label className='Radiobtn col-2 inline' onClick={displaySign}><input type='radio' className='radio-btn'
                                                value="9"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 9}
                                                onChange={handleOptionChange1}

                                            /> Undelivered</label>


                                            <label className='Radiobtn col-2'> <input type='radio' className='radio-btn'
                                                value="16"
                                                name="deliveryStatus"
                                                checked={outwarddetailStatus === 16}
                                                onChange={handleOptionChange1}
                                            /> OnHold</label>
                                        </div >
                                    </div>
                                <div className='col-12 inline' style={{ minHeight: 200 }}>
                                    <div className='col-8'>

                                        {(statusReasons.length !== 0) && (<div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Reason </label>
                                            <Select
                                                name="ReasonId"
                                                onChange={(e: any) => { setReasonId(e.value); setReasonLabel(e.label) }}
                                                options={statusReasons}
                                                value={{ value: ReasonId, label: ReasonLabel }}
                                                className='col-8'
                                            />
                                            {/* <input className='col-8'type="text"   value={remark} name='remark'onChange={e => setremark(e.target.value)} /> */}
                                        </div>)}

                                        {showSign && (<div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Received By</label>
                                            <input className='col-8' type="text"
                                                value={receivedBy} name='receivedBy' onChange={e => setreceivedBy(e.target.value)} />
                                        </div>)}
                                        <div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Status Date & Time</label>
                                            <input className='col-4' type="date"
                                                value={outwardDate} name='inwardDate' onChange={e => setoutwardDate(e.target.value)} />
                                            <input className='col-4' type="time" style={{}}
                                                value={outwardTime} name='inwardTime' onChange={e => setoutwardTime(e.target.value)} />
                                        </div>

                                        <div className='col-12 inline' style={{ marginTop: '10px' }}>
                                            <label className='col-4'>Remark</label>
                                            <input className='col-8' type="text" value={remark} name='remark' onChange={e => setremark(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        {showSign && (
                                            <div >
                                                <h5>Sign Box</h5>
                                                <div>
                                                    <SignatureCanvas
                                                        ref={signatureCanvasRef}
                                                        penColor="black"
                                                        canvasProps={{ width: 300, height: 100, className: 'sigCanvas border' }}
                                                    // canvasProps={{ width, height }}
                                                    />
                                                    <div>
                                                        {/* <button className='btn2 col-6' onClick={saveSignature}>Save Signature</button> */}
                                                        <button className='btn2 col-6' onClick={clearSignature}>Clear</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>


                            </div>
                            <div className='btn d-flex justify-content-center'>
                                <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleSubmit}><i className="fa fa-save"></i> Save</button>
                                <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                                <Link className="btn2 col-2 col-sm-1" to="/outwardLoglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                            </div>
                            </div>)}
                            {isChecked && (
                            <div className='btn d-flex justify-content-center'>
                                <button className="btn2 col-2 col-sm-1" type="button" onClick={HandleTracking}><i className="fa fa-save"></i> Update</button>
                                <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
                                <Link className="btn2 col-2 col-sm-1" to="/outwardLoglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Outwardbulkupdate