import  { useState, useEffect } from 'react';
import moment from 'moment';
import MasterDataService from '../../services/masterData.service';
import ReportContainerHeader from'../reports/ReportContainerHeader';
import ReportService from '../../services/report.service';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
const API_URL = process.env.REACT_APP_BASE_URL_API;

const InwardReport = () => {
  // const navigate = useNavigate();
  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('');
  const [modeId, setmodeId] = useState<Number | 0>(0);
  const [fromdate, setfromDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [todate, settoDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [Employee, setEmployee] = useState('');
  const [EmployeeId, setEmployeeId] = useState(0);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [RouteList, setRouteList] = useState([]);
  const [Route, setRoute] = useState('');
  const [RouteId, setRouteId] = useState(0);
  const [Department, setDepartment] = useState('');
  const [DepartmentId, setDepartmentId] = useState(0);
  const [ReportTypeList,setReportTypeList] = useState<any | []>([]);
  const [ReportTypeId,setReportTypeId] = useState(1);
  const [ReportType,setReportType] = useState('Log Report');
  // const [excelpath,setexcelpath] = useState('');
  // const [bodyparams,setbodyparams] = useState();
  const mailRoomId = localStorage.getItem('mailRoomId');
  const branch = (localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";
  
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();

  const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  const timeformater = (data :any, row: any) => {
    if(data.length <= 8) {
      return (data)
    }else {
    const parsedDate = moment(data, "YYYY-MM-DD@HH:mm:ss");
    return (parsedDate.format('hh:mm A'))
    }
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean;  
    style: any;
    onSelect: any;
  }

  const selectRow: selectRowType = {
    mode: "radio",
    clickToSelect: true,
    style: { backgroundColor: '#c8e6c9' },
    onSelect: (row: any) => {
      setSelectedRecords(row.id);
    }
  }
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-red text-white'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };
 
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'Sr. No',  align: 'center'},
    { dataField: 'inwardDate', text: 'Log Date', sort: true  },
    { dataField: 'inwardTime', text: 'Log Time', formatter :timeformater },
    { dataField: 'batchNo', text: 'Batch Number' },
    { dataField: 'modeName', text: 'Mode Name', headerAlign: 'left'},
    { dataField: 'serviceProvider', text: 'Service Provider /Branch', headerAlign: 'left', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" ,  formatter: (cell, row :any) =>  `${row['serviceProvider']} ${row['frombranch']}`},
    { dataField: 'noOfShipment', text: 'Pcs.',  align: 'center' , style : { fontWeight :'bold', width : "75px"} },
    { dataField: 'totalEntriesDone', text: 'Entry Done',  align: 'center',style : {color : 'green', fontWeight :'bold', width : "75px"} },
    { dataField: 'pendingQuantity', text: 'Pending Entry',  align: 'center', style : {color : 'red', fontWeight :'bold', width : "75px"} },
    { dataField: 'logRemarks', text: 'Remark', style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }, classes:"showinline" },

  ];
  //Table End
const [allInwardLog, setallInwardLog] = useState([])
const [allInwardDetails, setAllInwardDetails] = useState([]);
const timeFormatChange  = (data: any, row: any) => {
  if(data.length > 8){
    const dataAsDate = new Date(data);
    if (!isNaN(dataAsDate.getTime())) {
      // Convert Date object to ISO string
      return  dataAsDate.toISOString().replace("1970-01-01T", "").substring(0, 8);
      // console.log('ISO String:', isoString);
    } else {
      console.error('Invalid date format:', data);
    } 
  } else {
    return data;
  }
}
 
const columnsDetails: ColumnDescription<selectRowType>[] = [
  { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
    return rowIndex + 1;
  }, text: 'Sr. No.',  align: 'center'},
  { dataField: 'inwardDate', text: 'Inward Date', sort: true },
  { dataField: 'inwardTime', text: 'Inward Time', formatter :timeFormatChange },
  { dataField: 'isrNumber', text: 'ISR Number' },
  { dataField: 'AWB', text: 'AWB Number' },
  { dataField: 'consignerName', text: 'Consignor' },
  { dataField: 'employeeName', text: 'Employee' },
  { dataField: 'inwardQuantity', text: 'Pcs.' },
  { dataField: 'inwarddetailStatus', text: 'Status' },
  { dataField: 'remark', text: 'Remark',  
  formatter: (cell, row :any) => {
    let remarksLabel = "";
    if (row.remark !== null && row.remark !="") {
      remarksLabel = `<span style="font-size: smaller;">Log :${row.remark}</span><br/>`;
    }
    if (row.missRouteReason !== null && row.missRouteReason !="") {
      remarksLabel += `<span style="font-size: smaller;">MissRoute : ${row.missRouteReason}</span><br/>`;
    }

    if (row.deliveryRemark !== null && row.deliveryRemark != "") {
      remarksLabel += `<span style="font-size: smaller;">Delivery : ${row.deliveryRemark}</span><br/>`;
    }     
    if (row.receivedBy !== null) {
      remarksLabel += `<span style="font-size: smaller;"> Receiver : ${row.receivedBy}</span>`;
    }

    return <div dangerouslySetInnerHTML={{ __html: remarksLabel }} />;
  }
},


];


  
 const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      // none of react-select's styles are passed to <Control />
      padding: 0,
      width: "100%",
      paddingLeft:"0px",paddingRight:"0px"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
  useEffect(() => {
    getShipmentMode();
    getServiceProvider();
    getEmployee();
    getroute();
    getdepartment();
    getReportType();
  }, [])
 
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          options.unshift({ value: 0, label: "All Mode" });
          await setshipmentmode(options)
          setmodeId(0)
          setmodeName("All Mode")
          }
        }
    );
  }
  const getServiceProvider = () => {
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.serviceProviderName
        }))
        options.unshift({ value: 0, label: "All Service Provider" });
        await setserviceProviderList(options)
        setserviceProviderId(0)
        setserviceProvider("All Service Provider")
       }
      }
    )
  }
  const getEmployee = () => {
    MasterDataService.getEmployeeList().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName
          }))
          options.unshift({ value: 0, label: "All Employee" });
          await setEmployeeList(options)
          setEmployeeId(0)
          setEmployee("All Employee")
        }
        }
    );
  }
  const getroute = () => {
    MasterDataService.getRouteId().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.routeName
          }))
          options.unshift({ value: 0, label: "All Route" });
          await setRouteList(options)
          setRouteId(0)
          setRoute("All Route")
       }
        }
    ).catch(error => {
      console.error("Error fetching Routes data:", error);
      // Handle the error from the API call
  });
  }
  const getdepartment = () => {
    MasterDataService.getDepartment().then(
        async (data) => {
          if(data) {            
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.name 
          }))
          options.unshift({ value: 0, label: "All Department" });
          await setDepartmentList(options)
          setDepartmentId(0)
          setDepartment("All Department")
        }
        }
    ).catch(error => {
      console.error("Error fetching department data:", error);
      // Handle the error from the API call
  });
  }
  const getReportType = () => {
           
          const options =  [
          {
              "value": 1,
              "label": "Log Report"
          },
          {
            "value": 2,
            "label": "Detail Report"
          },
          {
            "value": 3,
            "label": "Delivery Report"
          },
          {
            "value": 4,
            "label": "Pending Report"
          },
          {
            "value": 5,
            "label": "Undelivered Report"
          },
          {
            "value": 6,
            "label": "Redirect Report"
          },
          {
            "value": 7,
            "label": "Onhold Report"
          },
          {
            "value": 8,
            "label": "Unclaimed Report"
          },
          {
            "value": 9,
            "label": "Regulatory Report"
          },
        ]
            setReportTypeList(options) 
  }

const handleModeChange = (e:any) => {
setmodeId(e.value);
setmodeName(e.label)
  }
  const handleEmployee = (e:any) => {
    setEmployeeId(e.value);
    setEmployee(e.label)
  }
  const handleRoute = (e:any) => {
    setRouteId(e.value);
    setRoute(e.label)
  }
  const handleDepartment = (e:any) => {
    setDepartmentId(e.value);
    setDepartment(e.label)
  }
  const handleServiceProvider = (e:any) => {
    setserviceProviderId(e.value);
    setserviceProvider(e.label)
  }

  const handleReportType = (e:any) => {
    setReportTypeId(e.value);
    setReportType(e.label);
  }

const generateinwardreport = async () => {
    let errorMessage = "";
    
    if((ReportType !== "") && (fromdate !== "") && (todate !== "")) {
        interface MyObject {
        fromDate:string,
        toDate:string,
        modeId?:Number,
        serviceProviderId?:number,
        EmployeeId?:number,
        RouteId?:number,
        DepartmentId?:number
        mailRoomId:any,
        branch:number
       }
    
    let bodyParamter: MyObject = {
        fromDate:fromdate,
        toDate:todate,
        mailRoomId:mailRoomId,
        branch:branch
    }
    if(modeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          modeId: modeId,
      }
      }
      if(serviceProviderId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          serviceProviderId: serviceProviderId,
      }
      }
      if(EmployeeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          EmployeeId: EmployeeId,
      }
      }
      if(RouteId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          RouteId: RouteId,
      }
      }
      if(DepartmentId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          DepartmentId: DepartmentId,
      }
      }
    await ReportService.generateinwardreport(
      bodyParamter,ReportTypeId
    ).then(
       (data) => {
          const link = document.createElement('a');
          link.href = API_URL+"uploads/"+data; // Replace with the actual file path
          link.download = data;
          // link.target = '_blank'; // Optional: Specify a different filename for the downloaded file
          link.click();
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((fromdate === "") && (todate === "")) {
      errorMessage = "Please Enter Date Range !!";
    } else if (ReportType === "") {
      errorMessage = "Please select report type !!";
    } 
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
  }
  
  useEffect(()=>{
    const showreport = async () => {
    let errorMessage = "";
    
    if((ReportType !== "") && (fromdate !== "") && (todate !== "")) {
        interface MyObject {
        fromDate:string,
        toDate:string,
        modeId?:Number,
        serviceProviderId?:number,
        EmployeeId?:number,
        RouteId?:number,
        DepartmentId?:number
        mailRoomId:any,
        branch:number
       }
    
    let bodyParamter: MyObject = {
        fromDate:fromdate,
        toDate:todate,
        mailRoomId:mailRoomId,
        branch:branch
    }
    if(modeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          modeId: modeId,
      }
      }
      if(serviceProviderId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          serviceProviderId: serviceProviderId,
      }
      }
      if(EmployeeId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          EmployeeId: EmployeeId,
      }
      }
      if(RouteId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          RouteId: RouteId,
      }
      }
      if(DepartmentId !== 0)
      {
        bodyParamter = {
          ...bodyParamter,
          DepartmentId: DepartmentId,
      }
      }
    await ReportService.showinwardreport(
      bodyParamter,ReportTypeId
    ).then(
       (data) => {
        if(ReportTypeId === 1)
{   setallInwardLog(data) } else {
  setAllInwardDetails(data)
}
      }
    ).catch((error) => {
      console.log(error)
    })
  } else {
    if ((fromdate === "") && (todate === "")) {
      errorMessage = "Please Enter Date Range !!";
    } else if (ReportType === "") {
      errorMessage = "Please select report type !!";
    } 
    
    Swal.fire({
      position: 'center',
      icon: 'warning',
      title: errorMessage,
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        title: 'swal-fire-alert', // Add your custom CSS class here
      },
    });
  }
    }
    showreport()
  },[ReportType, fromdate, todate, modeId, serviceProviderId, EmployeeId,RouteId, DepartmentId])

  
  const filteredData = Array.isArray(allInwardLog)
  ? allInwardLog.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
  : []; 
  const filteredDataDetails = Array.isArray(allInwardDetails)
  ? allInwardDetails.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): boolean => {
          if (typeof value === 'string' || typeof value === 'number') {
            const stringValue = String(value).toLowerCase();
            return stringValue.includes(searchQuery.toLowerCase());
          }
          return false;
        }
      );
    })
  : [];
  return (
    <>
    <ReportContainerHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
        <div className="themeColor width" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
  <p style={{ marginLeft: "10px" }}>Inward Report</p>
  <div className='insameline'>
    <input type='date' className='col-6 zeroPadding' value={fromdate} max={moment().format("YYYY-MM-DD")} name='fromdate' onChange={e => setfromDate(e.target.value)} />
    <input type='date' className='col-6 zeroPadding' value={todate} max={moment().format("YYYY-MM-DD")} name='todate' onChange={e => settoDate(e.target.value)} />
  </div>
</div>

      <div className='col-12' >   
      <div className=' col-sm-12 inputs'>
          <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="ReportType"
                      onChange={handleReportType}
                      options={ReportTypeList}
                      styles={customStyles}
                      value={{ value: ReportTypeId, label: ReportType }}
                    />
                    </div>
                      
       
      <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select
                      name="modeId"
                      onChange={handleModeChange}
                      options={shipmentmode}
                      styles={customStyles}
                      value={{ value: modeId, label: modeName }}
                    />
            </div>
            <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="serviceProviderId"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      styles={customStyles}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
                    </div>
                    <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="EmployeeId"
                      onChange={handleEmployee}
                      options={EmployeeList}
                      styles={customStyles}
                      value={{ value: EmployeeId, label: Employee }}
                    />
                    </div>
                    <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="RouteId"
                      onChange={handleRoute}
                      options={RouteList}
                      styles={customStyles}
                      value={{ value: RouteId, label: Route }}
                    />
                    </div>
                    <div className='col-2 select' style={{paddingLeft:"0px",paddingRight:"0px"}}>
          <Select     name="DepartmentId"
                      onChange={handleDepartment}
                      options={DepartmentList}
                      styles={customStyles}
                      value={{ value: DepartmentId, label: Department }}
                    />
                    </div>
       </div> 
      
       <div className="table-responsive tableContainer">
    {ReportTypeId === 1 &&( <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              // pagination={pagination}
              // selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />)          
    }
   {ReportTypeId != 1 &&( <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%'}}
              keyField='id'
              striped
              hover
              condensed
              data={filteredDataDetails}
              columns={columnsDetails}
              // pagination={pagination}
              // selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />)          
    }
            </div>
     </div>
      <div>
        <div className='btn d-flex justify-content-center'> 
  <a className="btn2 col-2 col-sm-1"  type="button" onClick={generateinwardreport} 
           ><i className="fa fa-download"></i> Generate</a>  
         <Link className="btn2 col-2 col-sm-1" to=""> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</Link>
      </div>
      </div>
      </div>
      </div>
    </>
  )
}

export default InwardReport