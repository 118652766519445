import  { useEffect, useRef, useState } from "react";
// import '../inward/InwardDelivarySheet.css';
import Outcontainerheader from "./Outcontainerheader";
import MasterDataService from "../../services/masterData.service";
import Manifest from "../../services/manifest.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
// import cellEditFactory from "react-bootstrap-table2-editor";

import Swal from "sweetalert2";
import moment from "moment";


function OutwardManifest() {
const navigate = useNavigate();
const inputRefs = useRef<{ [key: number]: { [key: string]: HTMLInputElement | null } }>({});

const [serviceproviderLabel, setserviceproviderLabel] = useState('Select Service Provider');
const [serviceprovider, setserviceprovider] = useState(0);
const [serviceprovidervalue, setserviceprovidervalue] = useState([])
const [modeId, setmodeId] = useState(0);
const [modeIDvalue, setmodeIDvalue] = useState([]);
const[modeName,setmodeName] = useState('Select Mode')
const[optionWiseOutwardData,setoptionWiseOutwardData] = useState([])
const [selectedRecord, setselectedRecord] = useState<any |[]>([])
const { state } = useLocation();
const [deliveredBy, setdeliveredBy] = useState(0)
const [deliveryByName, setdeliveryByName] = useState('')
const [allDeliveryBoy, setAlldeliveryBoy] = useState([])
const [manifestNumber, setmanifestNumber] = useState("");
const [tobranch, settobranch] = useState([])
const [tobranchId, settobranchId] = useState(0)
const [tobranchName, settobranchName] = useState("")

const [manifeststatusId, setmanifeststatusId] = useState(22)
const [manifeststatusLabel, setmanifeststatusLabel] = useState('Out for Vendor')
const [manifeststatuslist, setmanifeststatuslist] = useState([
  {
    "value":22,
     "label":"Out for Vendor"
  },
  {
    "value":23,
     "label":"Out for Branch"
  },
  {
    "value":5,
     "label":"Out for Delivery"
  },
])

const [showDeliverydiv, setshowDeliverydiv] = useState(false);
const [showbranchdiv, setshowbranchdiv] = useState(false);
const [outwarddetails, setoutwarddetails] = useState<any | []>([]);



useEffect(()=>{
    if(state){
    getManifestDetails()
    } else {
    getManifestData();
    }
    getDeliveryBoy();  
    getServiceprovider();
    getModeId();     
    gettobranch();
}, [])

useEffect(()=>{
  getManifestData()
},[serviceprovider, modeId])

const getManifestDetails = async () => {
    if(state !== null){
   await Manifest.getmanifestDetails(state).then(
        (data)=>{
            if(data){
                setoptionWiseOutwardData(data.inwardDetails)
                setdeliveredBy(data.drsMaster.deliveredBy)
                setserviceprovider(data.drsMaster.serviceprovider)
                setmodeId(data.drsMaster.routeId)
                setmanifestNumber(data.drsMaster.drsNumber)
            }
        }
    )
}
}
 //  get Servive provider options
 const getServiceprovider = async () => {
    await MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.serviceProviderName
        }))
        setserviceprovidervalue(options)
        options.unshift({ value: 0, label: "Select service Provider" });
        if(state === null){
          if (data[0]) {
            // await setserviceprovider(data[0]['id'])
            // await setserviceproviderLabel(data[0]['serviceProviderName'])
        } 
      } else {
        let serviceproviderLists = data.find((item: any) => {
          return item.id === serviceprovider
        });
        if (serviceproviderLists !== undefined) {
          setserviceproviderLabel(serviceproviderLists.serviceProviderName)
        }
      }     
      }

      }
    )
  }
const getDeliveryBoy = async () => {
    await MasterDataService.GetdeliveryBoy().then(
        async (data) => {
            if(data) {
                const options = data.map((d: any) => ({
                    "value": d.id,
                    "label": d.deliveryBoyName
                  }))
               await setAlldeliveryBoy(options);
               if(state === null){
                if (data[0]) {
                    // setdeliveredBy(data[0]['id'])
                    // setdeliveryByName(data[0]['deliveryBoyName'])
                }
            }else {
                let deliveryBoysList = data.find((item: any) => {
                    return item.id === deliveredBy
                  });
                  if (deliveryBoysList !== undefined) {
                    setdeliveryByName(deliveryBoysList.deliveryBoyName)
                  } 
            }

            }
        }
    )
}
  const getModeId = async () => {
    await MasterDataService.GetshipmentMode().then(
      async (data) => {
        if(data)
        {
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.modeName
        }))
        await setmodeIDvalue(options)
        if(state === null){
          if (data[0]) {
            
          }} else {
            let modeList = data.find((item: any) => {
              return item.id === modeId
            });
            if (modeList !== undefined) {
              setmodeName(modeList.modeName)
            }
          }
        }
 }
    )
  }
  const gettobranch= async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if(data)
        {
         // console.log("Data",data);
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.branchName
        }))
        await settobranch(options)
        if(state === null){
          if (data[0]) {
            
          }} else {
            let tobranchList = data.find((item: any) => {
              return item.id === tobranchId
            });
            if (tobranchList !== undefined) {
              settobranchName(tobranchList.branchName)
            }
          }
        }
 }
    )
  }

  const getManifestData = () => {
    if(state === null){
      Manifest.getOutwardData(serviceprovider, modeId).then(
        (data)=>{
            if(data){
            const newData =  data.map((rowData :any)=>({...rowData, checkboxChecked : false, vendorAWB : ""}))
            console.log(newData)
                setoptionWiseOutwardData(newData)
            }
        }
    )
    }
  }

  useEffect(()=>{
    if(manifeststatusId === 5)
    {setshowDeliverydiv(true);
     setshowbranchdiv(false)
   }
    else if(manifeststatusId === 23)
    {
     setshowbranchdiv(true)
     setshowDeliverydiv(false);
   }else 
   {
    setshowbranchdiv(false)
    setshowDeliverydiv(false);
  }
  },[manifeststatusId])
 
// const handleInputChange = (e:any, index :number) =>{
// console.log(e)
// }
const handleCheckbox = (index :any, removeValue : Number) =>{
  // (inputRefs.current[index]['AWB'])?.readOnly
  // console.log("selectedRecord", selectedRecord)
  const updatedData:any = [...optionWiseOutwardData];
  if(updatedData[index].checkboxChecked === true){
    // console.log(index)
    updatedData[index].AWB = "";
    const updatedSelectedRecord = selectedRecord.filter((value : any) => value !== removeValue);
    // console.log("updatedSelectedRecord", updatedSelectedRecord)
setselectedRecord(updatedSelectedRecord);
  }
  console.log("updatedSelectedRecord", selectedRecord)
  console.log("updatedData[index].checkboxChecked", updatedData[index].checkboxChecked)
  updatedData[index].checkboxChecked = !updatedData[index].checkboxChecked;
  setoptionWiseOutwardData(updatedData);
}
  const handleInputChange = (e :any, rowIndex:any, row:any) => {
    const { name, value } = e.target;
    // Clone the current data array to avoid directly mutating state
    const updatedData:any = [...optionWiseOutwardData];
    updatedData[rowIndex][name] = value; // Update the data for the specific column
    setoptionWiseOutwardData(updatedData);    
             }

  const handleSubmit = () => { 
    if(manifeststatusId != 0){
    const meniDate = (moment(new Date())).format('YYYY-MM-DD')
    const manifestTime=moment().format('HH:mm:ss')
    var serviceproviderdata = 0;
    if(manifeststatusId === 22)
    {
      var serviceproviderdata = serviceprovider;
    }
   
  const  outwarddetails1 =  optionWiseOutwardData.filter((row :any) => row.checkboxChecked === true);
  console.log("outwarddetails", optionWiseOutwardData);
  if(outwarddetails1.length != 0){
    Manifest.postManifestData(outwarddetails1, meniDate, manifestTime, deliveredBy, serviceproviderdata,manifeststatusId,tobranchId).then(
        (data) =>{
            if(data){
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: data,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => { 
                navigate('../outwardmenifestlist');
              });
               
            }
        }
    )
      }else {
        Swal.fire({
          position: 'center',
          title: "Data Warning",
          icon: 'warning',
          text: "Please select atlease one outward entry",
        }) 
      }
  } else {
    Swal.fire({
      position: 'center',
      title: "Data Warning",
      icon: 'warning',
      text: "Please select outward entry and/or status",
    }) 
  }
}
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Manifest.deleteManifest(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Outward Manifest has been deleted",
          }).then(function () {
           navigate('../outwardmanifestlist')
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Outward Manifest is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }
  
    return (
        <>
            <Outcontainerheader />
                <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%", paddingBottom : '10%' }}>
                    <div className="container " style={{}}>
                    <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Outward Manifest Entry </p></div>
                    <div className="inline" style={{marginBottom: 10}}>
                                <div className="col-2" style={{padding:1, paddingLeft: 5}}>Mode Name  </div>
                                <Select
                                        name="modeId"
                                        onChange={(e:any)=>{setmodeId(e.value); setmodeName(e.label);}}
                                        options={modeIDvalue}
                                        value={{ value: modeId, label: modeName }}
                                        className='col-3'
                                        />
                                <div className="col-2" style={{padding:1}}>
                                    <label>Service Provider</label>
                                </div>
                                <Select
                                        name="serviceprovider"
                                        onChange={(e :any)=>{ setserviceprovider(e.value); setserviceproviderLabel(e.label); }}
                                        options={serviceprovidervalue}
                                        value={{ value: serviceprovider, label: serviceproviderLabel }}
                                        className='col-3'
                                        />
                                
                                                        </div>
                        <div className="width tableContainer">
                          <div>
                            <table className="table">
                              <thead style={{fontWeight:600}}>
                                <tr>
                                  <td> Sr. NO </td>
                                  <td> Outward Date</td>
                                  <td> OSR Number</td>
                                  <td> Employee </td>
                                  <td> Consignee</td>
                                  <td> Destination</td>
<td></td>
                                  <td> AWB Number</td>
                                  </tr>
                              </thead>
                              <tbody>
{optionWiseOutwardData?.map((row :any, index)=>{
  return (<tr key={index}>
    <td>{index + 1} </td>
    <td> {row.outwardDate}  </td>
    <td> {row.osrNumber}  </td>
    <td> {row.employeeName}</td>
    <td> {row.consigneeName} </td>
    <td> {row.consigneeCityName} - {row.consigneeZipCode} </td>
    <td><input type="checkbox"  name="checkbox" checked={row.checkboxChecked} onChange={()=>{handleCheckbox(index, row.id)}} /></td>
    <td> <input type="text" placeholder="Please Click the checkbox to enter AWB Number" name="AWB" style={{width : '100%'}}  value={row.AWB} readOnly={!row.checkboxChecked} onChange={(e) => handleInputChange(e, index, row)}/>
    </td> 
  </tr>)
})}
                                
                              </tbody>
                            </table>

                          </div>
                        <div className="">
                             {/* <BootstrapTable
                                keyField='id'
                                data={optionWiseOutwardData}
                                columns={ columns }
                                cellEdit={ cellEditFactory({
                                  mode: 'click',
                                  blurToSave: true
                                })}
                                selectRow={ selectRow }
                                /> */}
                           
                        </div>
                        </div>
                        <div><hr/></div>
                        <div className="width">
                            <div className="inline">
                              <div className="col-10 inline">
                            <label className="col-1">Status</label>
                            <Select
                                        name="manifeststatus"
                                        onChange={(e :any)=>{ setmanifeststatusId(e.value); setmanifeststatusLabel(e.label);  }}
                                        options={manifeststatuslist}
                                        value={{ value: manifeststatusId, label: manifeststatusLabel }}
                                        className='col-3'
                                        />
                                        {showDeliverydiv ? <>
                               <label className="col-2"> Delivery BY</label>
                            <Select
                                        name="deliveredBy"
                                        onChange={(e:any)=>{setdeliveredBy(e.value); setdeliveryByName(e.label)}}
                                        options={allDeliveryBoy}
                                        value={{ value: deliveredBy, label: deliveryByName }}
                                        className='col-3'
                                        />
                                        </>:""}
                                        {showbranchdiv ? <>
                               <label className="col-2"> Out for Branch</label>
                            <Select
                                        name="outbranch"
                                        onChange={(e:any)=>{settobranchId(e.value); settobranchName(e.label)}}
                                        options={tobranch}
                                        value={{ value: tobranchId, label: tobranchName }}
                                        className='col-3'
                                        />
                                        </>:""}
                                        </div>
                                        <div className="col-2 bottom-btn ">
                            {(state === null) ? <>
                             <button className="btn2 bottom-btns col-12" onClick={handleSubmit}> Save</button></>
                                          : <>  <button className="btn2 bottom-btns col-6" onClick={handleDelete}> Delete</button>
                                              <a className="btn2 bottom-btns col-6"  href={(manifestNumber !=="") ? process.env.REACT_APP_BASE_UPLOAD_API+"manifestpdf/"+manifestNumber+".pdf" :"#"}
                      download={`${manifestNumber}.pdf`}
                      target="_blank"
                      rel="noreferrer"><i className="fa fa-print"></i> Print</a> </> }
                      </div>
                            </div>
                            </div>
                </div>
                </div>
                </div>
        </>



    );
} export default OutwardManifest;